import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="About me" />
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
      className="col-small-row-large"
    >
      <div style={{ marginLeft: "1rem" }}>
        <h2>
          Hi, I'm Sander Philipse, a software engineer based in Amsterdam.
        </h2>
        <p>
          I'm a senior software engineer with extensive experience across the
          entire development stack, focusing on scaleable, maintainable,
          high-traffic web apps.
        </p>
        <p>
          I take ownership of features and products, drive implementation and
          architectural decisions, and work collaboratively to improve the
          entire development team's skills. I'm relentlessly focused on user
          experience, and a big believer in inclusive, accessible design and
          iterative development.
        </p>{" "}
        <p>
          I think the key to maintainable, scaleable and reliable applications
          is{" "}
          <a href="https://www.felienne.com/book">
            code that is easy to understand
          </a>
          . I'm a big fan of functional, reactive approaches to code, and I
          think message-based architectures provide a great solution to many
          problems in modern software development. I believe CICD and test
          suites following{" "}
          <a href="https://martinfowler.com/articles/practical-test-pyramid.html">
            the test pyramid
          </a>{" "}
          are essential parts of modern software development. I prioritize
          making my colleagues' lives easier by automating tasks, developing
          guidelines and implementing a consistent software architecture.
        </p>
        <h3>Other accomplishments</h3>
        <ul>
          <li>I hold a BA and MA in history.</li>
          <li>
            I{" "}
            <a href="https://www.trouw.nl/nieuws/hoe-je-je-moet-gedragen-in-het-verhitte-debat-over-het-koloniaal-verleden~b57e4905b/">
              was once attacked
            </a>{" "}
            in a national Dutch newspaper by two professors emeritus for
            writing too harshly of Dutch historical slavery and racism.
          </li>
          <li>
            Those two historians have since{" "}
            <a href="https://www.nytimes.com/2021/06/04/arts/design/rijksmuseum-slavery-exhibition.html">
              decisively lost the historical argument
            </a>{" "}
            with their colleagues.
          </li>
          <li>I was once an excellent online poker player.</li>
          <li>
            I spent years being paid to{" "}
            <a href="https://www.bucsnation.com/authors/sander-philipse">
              write about the NFL
            </a>{" "}
            by Vox Media while never setting foot on American soil.
          </li>
        </ul>
        <h3>About this website</h3>
        <p>
          This website was built using{" "}
          <a href="https://www.gatsbyjs.com">Gatsby</a> and React. The code
          block color scheme is is based on{" "}
          <a href="https://github.com/ericwbailey/a11y-syntax-highlighting">
            Eric Bailey's accessible color scheme
          </a>{" "}
          for syntax highlighting and adheres to the{" "}
          <a href="https://www.w3.org/WAI/standards-guidelines/wcag/">
            WCAG accessibility guidelines
          </a>
          .
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
